import Repository from "@/modules/common/utils/Repository";
import store from "@/store"
import {LOGIN} from "@/modules/auth/store/actions/AuthActions";

export default {
  login(username, password) {
    const data = {username:username, password:password}
    return Repository.loginApi.post('login', data).then(response => {
      let token = response.data.data.id_token
      if (token) {
        return store.dispatch(LOGIN, token)
      } else {
        let errorMessage = response.data.data.error_description || "Login Error"
        return Promise.reject(errorMessage)
      }
    }).catch(error => {
      return Promise.reject(error)
    })
  }
}
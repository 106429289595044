<template>
  <v-row class="my-0">
    <v-col cols="5" class="d-none d-sm-inline-block d-sm-none d-md-inline-block login-first-column px-8">
      <v-img max-width="171" max-height="32" class="d-block mt-11 mb-8" src="@/assets/images/sorcero-white.png"/>
      <h2 class="mb-4 openSans-400 fz-24 txt-white" v-text="$vuetify.lang.t('$vuetify.login.labels.welcome')"></h2>
      <h3 class="mb-11 openSans-300 fz-24 txt-white" v-text="$vuetify.lang.t('$vuetify.login.labels.labelHook')"></h3>
      <v-img max-width="555" max-height="465" class="mx-auto margin-image" src="@/assets/images/login-ilu-x2.png"/>
    </v-col>
    <v-col class="form-container">
      <login-form :error="error" @login="login"/>
    </v-col>
  </v-row>
</template>

<script>
import LoginForm from '@/modules/login/components/LoginForm.vue'
import LoginService from "@/modules/login/services/LoginService";
import router from "@/router";

export default {

  name: 'Login',
  components: { LoginForm },

  data () {
    return {
      error: null
    }
  },
  methods: {
    login(data) {
      this.error = null
      LoginService.login(data.username, data.password).then(() => {
        router.push('/projects')
      }).catch(() => {
        this.error = this.$vuetify.lang.t('$vuetify.login.labels.errorLogin')
      })
    }
  }
}
</script>

<style lang="css" scoped>
.login-first-column {
  min-height: calc(100vh);
  background-image: url('../../../assets/images/login-bg-x2.png');
  background-size: cover;
}

.margin-image {
  margin-top: 20%;
}

.welcome-label {
  color: #09609F;
}

.form-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
<template>
  <div class="w-60">
    <v-row class="mb-5" justify="center">
      <v-img max-width="120" src="@/assets/images/logo_sorcero.svg"/>
    </v-row>
    <v-text-field v-model="email" :label="$vuetify.lang.t('$vuetify.login.inputs.email')" outlined></v-text-field>
    <v-text-field
      v-model="password"
      :label="$vuetify.lang.t('$vuetify.login.inputs.password')"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      :error="error != null"
      @click:append="showPassword = !showPassword"
      outlined></v-text-field>
    <v-alert v-if="error" color="red" dense dismissible outlined prominent text
        type="error">{{ error }}</v-alert>
    <div class="mb-5 ta-right">
      <a>{{ $vuetify.lang.t('$vuetify.login.labels.forgotPassword') }}</a>
    </div>
    <v-btn class="col-8" block color="primary" @click="login" >{{ $vuetify.lang.t('$vuetify.login.buttons.login') }}</v-btn>
    <div class="my-5 ta-center cursor-default">
      <span class="mr-2">{{ $vuetify.lang.t('$vuetify.login.labels.doNotHaveAccount') }}</span>
      <span class="link-disabled">{{ $vuetify.lang.t('$vuetify.login.labels.signUp') }}</span>
    </div>
  </div>
</template>

<script>
export default {

  name: 'LoginForm',

  data () {
    return {
      email: '',
      password: '',
      showPassword: false
    }
  },
  props:{
    error: String
  },
  methods: {
    login() {
      this.$emit('login', {username: this.email, password: this.password})
    }
  }
}
</script>

<style lang="css" scoped>

.cursor-default {
  cursor: default;
}

.link-disabled {
  color: #a1a1a1;
}

.w-60 {
  width: 60% !important;
}
</style>